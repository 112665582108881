import '../styles/Projects.css';

const Projects = (props) => (
  <div className="projectWrap">
    <section className='header'>
      <header className='titolo'>FUCK HIP HOP</header>
      <p>(WORK IN PROGRESS)</p>
    </section>
    <img alt='' className='cover' src="https://ik.imagekit.io/7ol7k87qcca/Boia/WhatsApp%20Image%202024-09-02%20at%2017.22.26_KraZXgPe-.jpeg?updatedAt=1725817089704" />
    <a className="more" href="mailto:jacopotestone@gmail.com">LEARN MORE</a>
    <div style={{ width: "80vw", height: "1px", backgroundColor: "white", margin: "100px 0" }} />
    <section className='header'>
      <header className='titolo'>FELICE PER SEMPRE</header>
      <p>(WORK IN PROGRESS)</p>
    </section>
    <img alt='' className='cover' src="https://ik.imagekit.io/7ol7k87qcca/Boia/WhatsApp_Image_2021-10-10_at_13.17.52_GGuEm-p6Le.jpeg?updatedAt=1633974238840" />
    <img alt='' className='page1' src="https://ik.imagekit.io/7ol7k87qcca/Boia/Felice_per_sempre_-_terza_stesura_prima_scena_pages-to-jpg-0001_Hv7EuYnLZ2AJ.jpg" />
    <img alt='' className='page2' src="https://ik.imagekit.io/7ol7k87qcca/Boia/ezgif-1-43d777a92742_GN19uaujhD.jpg" />
    <a className="more" href="mailto:jacopotestone@gmail.com">LEARN MORE</a>
  </div>
)


export default Projects;
