export const content = [
  {
    img : 'https://ik.imagekit.io/7ol7k87qcca/Boia/index_hyRJah-t7zi.jpg?tr=h-700,w-500)',
    trailer : 'https://weshort.com/app/live/collection?s=enlo-strumento-della-famaenitlo-strumento-della-famaiteslo-strumento-della-famaes'
  },
  {
    img : 'https://ik.imagekit.io/7ol7k87qcca/Boia/WhatsApp_Image_2021-01-22_at_19.48.47_Tj6U1CD5Z_0cifJdLWShBa.jpeg?tr=h-700,w-446)',
    trailer : 'https://www.youtube.com/embed/RFWGJMVBKDE'
  },
  {
    img : 'https://ik.imagekit.io/7ol7k87qcca/Boia/Untitled_1__opVKCgJHd.jpeg?updatedAt=1682331406009',
    trailer : 'https://www.youtube.com/embed/ogA0rFLKlnM'
  },
  {
    img : 'https://ik.imagekit.io/7ol7k87qcca/tr:w-640,h-732,cm-extract,x-500,y-87/Boia/WhatsApp_Image_2021-03-03_at_19.11.47_tqtPq-0v7YB4.jpeg',
    trailer : 'https://www.youtube.com/embed/StNavYTLtXg'
  }
]
