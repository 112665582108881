import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'

import '../styles/Works.css';
import '../styles/Trailer.css';
import { content } from './Content'

const Card = (props) => {
  const [overlay, setOverlay] = useState(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1225px)'
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  const toggleOver = () => {
    setOverlay(true)
  }

  const closeOver = () => {
    setOverlay(false)
  }

  return (
    <>
      {isDesktopOrLaptop &&
        <div className='work'>
          <img alt="" className='img' src={props.img} />
          <div className="over">
            <p className='title'>{props.title}</p>
            {props.info ? <p className='info'>{props.info}</p> : null}
            {props.isExternal ? <a className="button" href={content[0].trailer} target="_blank" rel="noreferrer">{props.button}</a>
              : <p className="button" onClick={props.showTrailer}>{props.button}</p>}
          </div>
        </div>
      }
      {isTabletOrMobileDevice && <div className='workcont'>
        <div className='work' onClick={toggleOver}>
          <img alt="" className='img' src={props.img} />
        </div>
        {overlay ? (
          <div className="overlay">
            <div className="closeoverlay" onClick={closeOver}></div>
            <p className='title'>
              {props.title}
            </p>
            {props.info ? <p className='info'>{props.info}</p> : null}
            {props.isExternal ? <a className="button" href={content[0].trailer} target="_blank" rel="noreferrer">{props.button}</a>
              : <p className="button" onClick={props.showTrailer}>{props.button}</p>}
          </div>
        ) : null}
      </div>
      }
    </>
  )
}

const Cards = (props) => {
  const { t } = useTranslation();
  return (
    <div className="workscont">
      <Card title={t('title1')} img={content[0].img} info={t('card1')} showTrailer={props.showTrailer1} button={t('buttonFame')} isExternal />
      <Card title={t('title2')} img={content[1].img} info={t('card2')} showTrailer={props.showTrailer2} button={t('button')} />
      <Card title={t('title3')} img={content[2].img} info={t('card3')} showTrailer={props.showTrailer3} button={t('buttonFame')} />
      <Card title={t('title4')} img={content[3].img} info={t('card4')} showTrailer={props.showTrailer4} button={t('button')} />
    </div>
  )
}

const Trailer = (props) => (
  <div>
    <iframe title="trailer" className="trailer" src={props.trailer} type="video/mp4" controls allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>
    <div className="closetrailer" onClick={props.closeTrailer}></div>
  </div>
)

class Works extends React.Component {
  constructor() {
    super()
    this.state = {
      cards: true,
      lightbox: false,
      trailer: ''
    }
  }

  showTrailer1 = () => {
    this.setState({
      cards: false,
      lightbox: true,
      trailer: content[0].trailer
    })
  }

  showTrailer2 = () => {
    this.setState({
      cards: false,
      lightbox: true,
      trailer: content[1].trailer
    })
  }

  showTrailer3 = () => {
    this.setState({
      cards: false,
      lightbox: true,
      trailer: content[2].trailer
    })
  }

  showTrailer4 = () => {
    this.setState({
      cards: false,
      lightbox: true,
      trailer: content[3].trailer
    })
  }

  closeTrailer = () => {
    this.setState({
      cards: true,
      lightbox: false
    })
  }

  showOverlay = () => {

  }

  render() {

    return (
      <div>
        {this.state.cards ? <Cards showTrailer1={this.showTrailer1} showTrailer2={this.showTrailer2} showTrailer3={this.showTrailer3} showTrailer4={this.showTrailer4} /> : null}
        {this.state.lightbox ? (
          <div className='cont'>
            <Trailer trailer={this.state.trailer} closeTrailer={this.closeTrailer} />
          </div>
        ) : null}
      </div>
    )
  }
}



export default Works;
