import React from 'react'

import Background from './components/background';
import Flags from './components/Flags';
import Home from './components/Home'
import About from './components/About'
import Works from './components/Works'
import Projects from './components/Projects'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './styles/App.css'

const App = () => {

  return (
    <Router>
      <Background />
      <Flags />
      <div >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/works" element={<Works />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App;
