import React from 'react'
import { useTranslation } from 'react-i18next';

import '../styles/Home.css';

const Footer = (props) => (
  <footer className='links'>
    <a className="mail" href="mailto:jacopotestone@gmail.com">jacopotestone@gmail.com</a>
    <a href="https://www.imdb.com/name/nm7320332/" target='_blank' rel="noreferrer"><img alt="" className="imdb" src="https://ik.imagekit.io/7ol7k87qcca/tr:w-1292,h-533,cm-extract,x-378,y-756/Boia/logo-imdb-computer-icons-imdb-69c3645192c9fa3f482f47169a89d42d_1__u3I3-KecS.png" /></a>
    <a href="https://www.instagram.com/jacopotestone/" target='_blank' rel="noreferrer"><img alt="" className="insta" src="https://ik.imagekit.io/7ol7k87qcca/Boia/insta_tPGHuiq7Z.png" /></a>
  </footer>
)

const Home = () => {

  const { t } = useTranslation();

  const homeComponent = (
    <>
      <section className='name'>
        <p className='copy'>&copy; 2023</p>
        <header className='first'>jacopo</header>
        <header className='second'>testone</header>
      </section>
      <nav className="bar">
        <a className="About" id="about" href='/about'>{t('about')}</a>
        <div className="divide"></div>
        <a className="Works" href='/works'>{t('works')}</a>
        <div className="divide"></div>
        <a className="Projects" href='/projects'>{t('projects')}</a>
      </nav>
    </>
  )

  return (
    <div className="wrap">
      <div className="main">
        {homeComponent}
        <Footer />
      </div>
    </div>
  )
}

export default Home;
